import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCategorywiseSubCat } from "../../../store/action/homeAction";
import { useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import PropsTypes from "prop-types";
import { LogoutUser } from "../../../store/action/userAction";

function Header({ isMenuClose }) {
  const dispatch = useDispatch();
  // const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  // const [isClear, setIsClear] = React.useState(false);
  // const [searchText, setSearchText] = React.useState("");
  // const [isMenuOpen, setIsMenuOpen] = React.useState({ id: 0, type: false });
  const { getCategorywiseSubCategoryData } = useSelector((state) => state.home);
  const { userProfileData } = useSelector((state) => state.user);

  // React.useEffect(() => {
  //   if (isMenuClose && !isMenuClose.type && isMenuClose.id === 0) {
  //     setIsMenuOpen({ id: 0, type: false });
  //   }
  // }, [isMenuClose]);

  React.useEffect(() => {
    if (!getCategorywiseSubCategoryData) {
      dispatch(GetCategorywiseSubCat());
    }
  }, [dispatch, getCategorywiseSubCategoryData]);

  // const closeAnimation = React.useCallback(() => {
  //   const searchInput = document.getElementById("search-input");
  //   searchInput.style.animation = "";
  //   searchInput.style.animation = `move 0.2s linear 0s forwards`;
  //   const keyFrames = document.createElement("style");
  //   const fromWidth = window.innerWidth <= "1399" ? 250 : 300;
  //   const fromPaddingWidth = window.innerWidth <= "1399" ? 70 : 75;
  //   keyFrames.innerHTML = `
  //       @keyframes move{
  //         from {
  //           width: ${fromWidth}px;
  //           padding-right: ${fromPaddingWidth}px;
  //         }
  //         to {
  //           width: 50px;
  //           padding-right: 0px;
  //         }
  //       }
  //     `;
  //   searchInput.appendChild(keyFrames);
  //   setTimeout(() => {
  //     searchInput.style.animation = "";
  //     searchInput.style.width = "50px";
  //     searchInput.style.paddingRight = "0px";
  //   }, 1000);
  // }, []);

  const handleLogout = React.useCallback(() => {
    confirmDialog({
      message: "Are You Sure You Want To Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        dispatch(LogoutUser());
        navigate("/logout");
      },
      reject: () => {},
    });
  }, [navigate, dispatch]);

  // const handleSearch = React.useCallback(() => {
  //   // setIsClear(true);
  //   setIsOpen(false);
  //   setSearchText("");
  //   closeAnimation();
  // }, [closeAnimation]);

  // React.useLayoutEffect(() => {
  //   if (isOpen) {
  //     const searchInput = document.getElementById("search-input");
  //     searchInput.style.animation = `move 0.2s linear 0s forwards`;
  //     const keyFrames = document.createElement("style");
  //     const toWidth = window.innerWidth <= "1399" ? 250 : 300;
  //     const toPaddingWidth = window.innerWidth <= "1399" ? 70 : 75;

  //     keyFrames.innerHTML = `
  //       @keyframes move{
  //         from {
  //           width: 50px;
  //           padding-right: 0px;
  //         }
  //         to {
  //           width: ${toWidth}px;
  //           padding-right: ${toPaddingWidth}px;
  //         }
  //       }

  //     `;
  //     searchInput.appendChild(keyFrames);
  //     setTimeout(() => {
  //       searchInput.style.animation = "";

  //       if (window.innerWidth <= "1399") {
  //         searchInput.style.width = "250px";
  //         searchInput.style.paddingRight = "50px";
  //       } else {
  //         searchInput.style.width = "300px";
  //         searchInput.style.paddingRight = "50px";
  //       }
  //     }, 1000);
  //   }
  // }, [isOpen]);

  // const handleCloseInput = React.useCallback(() => {
  //   closeAnimation();
  // }, [closeAnimation]);

  // const handleOverflow = React.useCallback(() => {
  //   const element = document.getElementsByTagName("body");
  //   if (element) {
  //     element[0].style.height = "100vh";
  //   }
  // }, []);

  // const handleCloseFlow = React.useCallback(() => {
  //   const element = document.getElementsByTagName("body");
  //   if (element) {
  //     element[0].style.height = "";
  //   }
  // }, []);

  // when the user close the menu then used...
  // const handleCloseMenu = () => {
  //   setIsMenuOpen({ id: 0, type: false });
  // };

  return (
    <section className="header-section">
      <div className="top-header desk-view-nav">
        <div className="container-fluid">
          <div className="top-header-inner">
            <div className="left-option"></div>
            <Link className="cart-box" to={"/cart"}>
              <div className="image-left">
                <span className="image-box">
                  <img
                    src="/assets/img/feather-shopping-cart.svg"
                    width="15"
                    height="15"
                    alt="shopping-cart"
                    title="cart"
                  />
                </span>
                <span>Cart</span>
              </div>
              <div className="count">
                <span>10</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="nav-cst">
        <nav className="navbar navbar-expand-lg navbar-dark navbar-cst">
          <div className="container">
            <Link
              className="navbar-brand"
              to={sessionStorage.getItem("id") ? "/profile" : "/"}
            >
              <img
                src="/assets/img/playbook-Logo.png"
                alt="logo"
                width="155"
                height="55"
                title="Sport Logo"
              />
            </Link>
            <div className="desk-view-nav" id={"newclass-for-test"}>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* {getCategorywiseSubCategoryData &&
                getCategorywiseSubCategoryData.code === "200" ? (
                  getCategorywiseSubCategoryData.data.map((item, key) => (
                    <li className="nav-item has-megamenu" key={key}>
                      <div
                        className={
                          item.iCategoryId === isMenuOpen.id && isMenuOpen.type
                            ? "nav-link link hover-nav"
                            : "nav-link link"
                        }
                        onClick={() =>
                          setIsMenuOpen({
                            id:
                              item.iCategoryId === isMenuOpen.id &&
                              isMenuOpen.type
                                ? 0
                                : item.iCategoryId,
                            type:
                              item.iCategoryId === isMenuOpen.id &&
                              isMenuOpen.type
                                ? false
                                : true,
                          })
                        }
                      >
                        {item.vTitle}
                      </div>
                      {item.iCategoryId === isMenuOpen.id &&
                      isMenuOpen.type &&
                      item.subcategories &&
                      item.subcategories.length > 0 ? (
                        <div
                          className={
                            item.iCategoryId === isMenuOpen.id &&
                            isMenuOpen.type
                              ? "dropdown-menu megamenu active"
                              : "dropdown-menu megamenu"
                          }
                        >
                          <div className="mega-menu-list">
                            <ul>
                              {item.subcategories.map((subcategory, subkey) => (
                                <li key={subkey}>
                                  <div
                                    state={{
                                      iCategoryId: item.iCategoryId,
                                      iSubcategoryId:
                                        subcategory.iSubcategoryId,
                                    }}
                                    className="nav-link"
                                  >
                                    <Link
                                      className="subcate-title"
                                      to={"/products"}
                                    >
                                      {subcategory.vTitle}
                                    </Link>
                                    {subcategory.attributes &&
                                    subcategory.attributes.length > 0 ? (
                                      <div className="sub-menu">
                                        <ul>
                                          {subcategory.attributes.map(
                                            (attribute, attkey) => (
                                              <li key={attkey}>
                                                <Link
                                                  to={"/products"}
                                                  state={{
                                                    iCategoryId:
                                                      item.iCategoryId,
                                                    iSubcategoryId:
                                                      subcategory.iSubcategoryId,
                                                  }}
                                                >
                                                  {attribute.vTitle}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="dropdown-image">
                            <div className="image-box">
                              <img
                                src={
                                  item.vImage ||
                                  "assets/img/dropdown-banner.png"
                                }
                                alt="dropdown-banner"
                                width="345"
                                height="400"
                                title="Dropdown Banner"
                              />
                            </div>
                            <p></p>
                          </div>
                          <div className="close-btn" onClick={handleCloseMenu}>
                            <i className="far fa-times"></i>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </li>
                  ))
                ) : (
                  <></>
                )} */}
                {/* <li className="nav-item search-panel">
                  <form>
                    <div className="form-group">
                      <input
                        id="search-input"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search..."
                      />

                      <div className="image-box search-icon">
                        <img
                          src="/assets/img/fi-rr-search.png"
                          // onClick={
                          //   isOpen
                          //     ? () => {
                          //         setIsOpen(!isOpen);
                          //         handleSearch();
                          //       }
                          //     : () => setIsOpen(!isOpen)
                          // }
                          alt="searchbar"
                          width="18"
                          height="18"
                          title="Searchbar"
                        />
                      </div>
                    </div>
                  </form>
                </li> */}
                {sessionStorage.getItem("id") ? (
                  <li className="nav-item m-0">
                    <div className="dropdown user-ac-btn">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="far fa-user"></i>
                        <span>
                          {userProfileData && userProfileData.code === "200"
                            ? `${userProfileData?.data.vFirstName} ${userProfileData?.data.vLastName}`
                            : ""}
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link className="dropdown-item" to={"/profile"}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <div
                            onClick={handleLogout}
                            className="dropdown-item"
                            to={"/logout"}
                          >
                            Sign Out
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item ms-3">
                    <Link className="login-btn" to={"/login"}>
                      Sign In
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            <div className="mobile-view-nav">
              <div className="inner-btn">
                {sessionStorage.getItem("id") ? (
                  <li className="nav-item m-0">
                    {/* <Link className="login-btn" to={"/logout"}>
                      Sign Out
                    </Link> */}
                    <div className="dropdown user-ac-btn">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="far fa-user"></i>
                        <span>
                          {userProfileData && userProfileData.code === "200"
                            ? `${userProfileData?.data.vFirstName} ${userProfileData?.data.vLastName}`
                            : ""}
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <Link className="dropdown-item" to={"/profile"}>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <div
                            className="dropdown-item set-cursor-pointer"
                            onClick={handleLogout}
                            to={"/logout"}
                          >
                            Sign Out
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link className="login-btn" to={"/login"}>
                      Sign In
                    </Link>
                  </li>
                )}
                <button
                  className="navbar-toggler mobile-toggler"
                  type="button"
                  // onClick={handleOverflow}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="true"
                  aria-label="Toggle navigation"
                >
                  {/* <button className="navbar-toggler" type="button" >
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>

              <div
                className="navbar-collapse collapse"
                tabIndex="-1"
                id="navbarSupportedContent"
                name="navbarSupportedContent"
                aria-labelledby="navbarSupportedContentLabel"
              >
                <div className="nav-header">
                  <button
                    className="btn-close text-reset collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  ></button>
                </div>
                {/* <div className="left-option">
                  <ul>
                    <li>
                      <Link to={"/"}>Team Management</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Interactive Playbook</Link>
                    </li>
                    <li>
                      <Link to={"/"}>Social Media</Link>
                    </li>
                  </ul>
                </div> */}
                <div className="accordion" id="accordionExample">
                  {getCategorywiseSubCategoryData &&
                  getCategorywiseSubCategoryData.code === "200" ? (
                    getCategorywiseSubCategoryData.data.map((item, key) => (
                      <div className="accordion-item" key={key}>
                        <div className="accordion-header" id={`heading${key}`}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${key}`}
                            aria-expanded="false"
                            aria-controls={`collapse${key}`}
                          >
                            {item.vTitle}
                          </button>
                        </div>
                        {item.subcategories && item.subcategories.length > 0 ? (
                          <div
                            id={`collapse${key}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`heading${key}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul>
                                {item.subcategories.map(
                                  (subcategory, subkey) => (
                                    <li key={subkey}>
                                      <Link
                                        className="nav-link"
                                        to={"/products"}
                                        state={{
                                          iCategoryId: item.iCategoryId,
                                          iSubcategoryId:
                                            subcategory.iSubcategoryId,
                                        }}
                                      >
                                        {subcategory.vTitle}
                                      </Link>
                                      {subcategory.attributes &&
                                      subcategory.attributes.length > 0 ? (
                                        <div className="sub-menu">
                                          <ul>
                                            {subcategory.attributes.map(
                                              (attribute, attkey) => (
                                                <li key={attkey}>
                                                  <Link
                                                    to={"/products"}
                                                    state={{
                                                      iCategoryId:
                                                        item.iCategoryId,
                                                      iSubcategoryId:
                                                        subcategory.iSubcategoryId,
                                                    }}
                                                  >
                                                    {attribute.vTitle}
                                                  </Link>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <Link className="cart-box" to={"/cart"}>
                  <div className="image-left">
                    <span className="image-box">
                      <img
                        src="/assets/img/feather-shopping-cart.svg"
                        width="15"
                        height="15"
                        alt="shopping-cart"
                        title="cart"
                      />
                    </span>
                    <span>Cart</span>
                  </div>
                  <div className="count">
                    <span>- 10</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
}

Header.propTypes = {
  isMenuClose: PropsTypes.any,
};

export default Header;
