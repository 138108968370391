import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  GET_TEAM_BY_ID_REQUEST,
  GET_TEAM_BY_ID_SUCCESS,
  GET_TEAM_BY_ID_FAILURE,
  STORE_NAME_RESET,
  FIND_TEAM_REQUEST,
  FIND_TEAM_SUCCESS,
  FIND_TEAM_FAILURE,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAILURE,
  GET_ALL_TEAMS_REQUEST,
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_FAILURE,
  EDIT_TEAM_ID,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  GET_ALL_TEAMS_BY_USER_ID_REQUEST,
  GET_ALL_TEAMS_BY_USER_ID_SUCCESS,
  GET_ALL_TEAMS_BY_USER_ID_FAILURE,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE,
  GET_ALL_TEAMS_WITH_PARENTID_REQUEST,
  GET_ALL_TEAMS_WITH_PARENTID_SUCCESS,
  GET_ALL_TEAMS_WITH_PARENTID_FAILURE,
  SUBTEAM_BY_PARENTID_REQUEST,
  SUBTEAM_BY_PARENTID_SUCCESS,
  SUBTEAM_BY_PARENTID_FAILURE,
  CURRENT_TEAM,
  GET_TEAM_CREDITS_COUNTS_REQUEST,
  GET_TEAM_CREDITS_COUNTS_SUCCESS,
  GET_TEAM_CREDITS_COUNTS_FAILURE,
  VERIFY_TEAM_SLUG_REQUEST,
  VERIFY_TEAM_SLUG_SUCCESS,
  VERIFY_TEAM_SLUG_FAILURE,
  CURRENT_TEAM_ROLE,
  LOCK_TEAM_DETAILS,
  TEAM_MEMBER_LIST_SUCCESS,
  TEAM_MEMBER_LIST_REQUEST,
  // RESET_UPDATE_TEAM,
  UPDATE_TEAM_TAGLINE_REQUEST,
  UPDATE_TEAM_TAGLINE_SUCCESS,
  RESET_UPDATE_TEAM_TAGLINE,
  GET_TEAM_LIST_REQUEST,
  GET_TEAM_LIST_SUCCESS,
  GET_TEAM_LIST_FAILURE,
  STORE_SELECTED_TEAM_LIST_REQUEST,
  STORE_SELECTED_TEAM_LIST_SUCCESS,
  STORE_SELECTED_TEAM_LIST_FAILURE,
  GET_ALL_ASSOCIATED_TEAMS_REQUEST,
  GET_ALL_ASSOCIATED_TEAMS_SUCCESS,
  GET_ALL_ASSOCIATED_TEAMS_FAILURE,
  GET_ALL_SELECTED_TEAM_REQUEST,
  GET_ALL_SELECTED_TEAM_SUCCESS,
  GET_ALL_SELECTED_TEAM_FAILURE,
  RESET_STORE_SELECTED_TEAM,
  UPDATE_TEAM_NAME_REQUEST,
  UPDATE_TEAM_NAME_SUCCESS,
  UPDATE_TEAM_NAME_FAILURE,
  UPDATE_TEAM_NAME_RESET,
  CREATE_PARENT_TEAM_REQUEST,
  CREATE_PARENT_TEAM_SUCCESS,
  CREATE_PARENT_TEAM_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_RESET,
  DELETE_ALL_TEAM_REQUEST,
  DELETE_ALL_TEAM_SUCCESS,
  DELETE_ALL_TEAM_FAILURE,
  DELETE_ALL_TEAM_RESET,
} from "../constants/team";

const initialState = {};

export function teamReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_TEAM_REQUEST:
    case CREATE_PARENT_TEAM_REQUEST:
    case GET_TEAM_BY_ID_REQUEST:
    case TEAM_LIST_REQUEST:
    case FIND_TEAM_REQUEST:
    case GET_ALL_TEAMS_REQUEST:
    case UPDATE_TEAM_REQUEST:
    case GET_ALL_TEAMS_BY_USER_ID_REQUEST:
    case GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST:
    case GET_ALL_TEAMS_WITH_PARENTID_REQUEST:
    case SUBTEAM_BY_PARENTID_REQUEST:
    case GET_TEAM_CREDITS_COUNTS_REQUEST:
    case VERIFY_TEAM_SLUG_REQUEST:
    case TEAM_MEMBER_LIST_REQUEST:
    case UPDATE_TEAM_TAGLINE_REQUEST:
    case GET_TEAM_LIST_REQUEST:
    case STORE_SELECTED_TEAM_LIST_REQUEST:
    case GET_ALL_ASSOCIATED_TEAMS_REQUEST:
    case GET_ALL_SELECTED_TEAM_REQUEST:
    case UPDATE_TEAM_NAME_REQUEST:
    case DELETE_TEAM_REQUEST:
    case DELETE_ALL_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FIND_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        findTeamData: action.payload,
      };
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTeamsData: action.payload,
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        createTeamData: action.payload,
      };
    case GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        teamData: action.payload,
      };

    case TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teamListData: action.payload,
      };

    case TEAM_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        teamMemberListData: action.payload,
      };
    case GET_ALL_SELECTED_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSelectedTeamData: action.payload,
      };
    case GET_ALL_ASSOCIATED_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllAssociatedTeamData: action.payload,
      };
    case EDIT_TEAM_ID:
      return {
        ...state,
        loading: false,
        editTeamId: action.payload,
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTeamData: action.payload,
      };
    case UPDATE_TEAM_TAGLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTeamTagData: action.payload,
      };

    case GET_ALL_TEAMS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamsByUserData: action.payload,
      };
    case GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllParentTeamsByUserData: action.payload,
      };
    case GET_ALL_TEAMS_WITH_PARENTID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamsByParentIdData: action.payload,
      };
    case SUBTEAM_BY_PARENTID_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSubTeamsByParentIdData: action.payload,
      };
    case CURRENT_TEAM:
      return {
        ...state,
        loading: false,
        currentTeamId: action.payload,
      };
    case GET_TEAM_CREDITS_COUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getTeamCreditsAndCountData: action.payload,
      };
    case VERIFY_TEAM_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyTeamSlugData: action.payload,
      };
    case CURRENT_TEAM_ROLE:
      return {
        ...state,
        loading: false,
        currentTeamRoleId: action.payload,
      };
    case LOCK_TEAM_DETAILS:
      return {
        ...state,
        loading: false,
        lockTeamDetails: action.payload,
      };
    case STORE_SELECTED_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedTeamListData: action.payload,
      };
    case GET_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamListData: action.payload,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteTeamData: action.payload,
      };
    case DELETE_ALL_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteAllTeamData: action.payload,
      };
    case UPDATE_TEAM_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        updateTeamNameData: action.payload,
      };
    case CREATE_PARENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        createParentTeamData: action.payload,
      };
    case CREATE_PARENT_TEAM_FAILURE:
    case GET_TEAM_LIST_FAILURE:
    case VERIFY_TEAM_SLUG_FAILURE:
    case GET_TEAM_CREDITS_COUNTS_FAILURE:
    case SUBTEAM_BY_PARENTID_FAILURE:
    case GET_ALL_TEAMS_WITH_PARENTID_FAILURE:
    case GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE:
    case GET_ALL_TEAMS_BY_USER_ID_FAILURE:
    case FIND_TEAM_FAILURE:
    case CREATE_TEAM_FAILURE:
    case GET_TEAM_BY_ID_FAILURE:
    case TEAM_LIST_FAILURE:
    case GET_ALL_TEAMS_FAILURE:
    case UPDATE_TEAM_FAILURE:
    case STORE_SELECTED_TEAM_LIST_FAILURE:
    case GET_ALL_ASSOCIATED_TEAMS_FAILURE:
    case GET_ALL_SELECTED_TEAM_FAILURE:
    case UPDATE_TEAM_NAME_FAILURE:
    case DELETE_TEAM_FAILURE:
    case DELETE_ALL_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
      };
    case RESET_STORE_SELECTED_TEAM:
      return {
        ...state,
        loading: false,
        selectedTeamListData: {},
      };
    case UPDATE_TEAM_NAME_RESET:
      return {
        ...state,
        loading: false,
        updateTeamNameData: {},
      };

    case RESET_UPDATE_TEAM_TAGLINE:
      return {
        ...state,
        loading: false,
        updateTeamTagData: {},
      };
    case DELETE_TEAM_RESET:
      return {
        ...state,
        loading: false,
        deleteTeamData: {},
      };
    case DELETE_ALL_TEAM_RESET:
      return {
        ...state,
        loading: false,
        deleteAllTeamData: {},
      };

    case STORE_NAME_RESET:
      return initialState;
    default:
      return state;
  }
}
