import React from "react";
import ReactDOM from "react-dom";

// import "./assets/scss/custom.scss";

// import "./assets/css/fancybox.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

import AppWrapper from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode>
  <AppWrapper />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your AppWrapper, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
