import {
  RESET,
  GET_SELECTED_TEAM,
  GET_TEAM_WISE_DETAILS_REQUEST,
  GET_TEAM_WISE_DETAILS_SUCCESS,
  GET_TEAM_WISE_DETAILS_FAILURE,
  ALL_TEAM_LIST_REQUEST,
  ALL_TEAM_LIST_SUCCESS,
  ALL_TEAM_LIST_FAILURE,
  GET_TEAM_DATA_BY_TEAM_SLUG_REQUEST,
  GET_TEAM_DATA_BY_TEAM_SLUG_SUCCESS,
  GET_TEAM_DATA_BY_TEAM_SLUG_FAILURE,
  GET_TEAM_LIST_BY_PARENT_TEAM_REQUEST,
  GET_TEAM_LIST_BY_PARENT_TEAM_SUCCESS,
  GET_TEAM_LIST_BY_PARENT_TEAM_FAILURE,
} from "../constants/teamDetail";

const initialState = {};

export function teamDetailReducer(state = {}, action) {
  switch (action.type) {
    // case GET_ALL_SUB_CATEGORY_DATA_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    case GET_TEAM_WISE_DETAILS_REQUEST:
    case GET_TEAM_DATA_BY_TEAM_SLUG_REQUEST:
    case ALL_TEAM_LIST_REQUEST:
    case GET_TEAM_LIST_BY_PARENT_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_WISE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        teamIdByTeamData: action.payload,
      };
    case ALL_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allTeamList: action.payload,
      };
    case GET_TEAM_DATA_BY_TEAM_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        teamDataBySlug: action.payload,
      };
    case GET_SELECTED_TEAM:
      return {
        ...state,
        loading: false,
        selectedTeamData: action.payload,
      };
    case GET_TEAM_LIST_BY_PARENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllTeamByParentTeamData: action.payload,
      };
    case GET_TEAM_WISE_DETAILS_FAILURE:
    case ALL_TEAM_LIST_FAILURE:
    case GET_TEAM_DATA_BY_TEAM_SLUG_FAILURE:
    case GET_TEAM_LIST_BY_PARENT_TEAM_FAILURE:
      return {
        ...state,
        loading: true,
      };

    case RESET:
      return initialState;
    default:
      return state;
  }
}
