import { createStore, applyMiddleware, combineReducers } from "redux";
// import { logger } from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { userReducer } from "./userReducer";
import { bannerReducer } from "./bannerReducer";
import { companyReducer } from "./companyReducer";
import { dashboardReducer } from "./dashboardReducer";
import { sportsReducer } from "./sportsReducer";
import { otherReducer } from "./otherReducer";
import { coachReducer } from "./coachReducer";
import { teamReducer } from "./teamReducer";
import { teamNewsReducer } from "./teamNewsReducer";
import { teamPlayerReducer } from "./teamPlayerReducer";
import { canvasReducer } from "./canvasReducer";
import { postReducer } from "./postReducer";
import { eventReducer } from "./eventReducer";
import { homeReducer } from "./homeReducer";
import { chatReducer } from "./chatReducer";
import { teamSportsReducer } from "./teamSportsReducer";
import { moduleMasterReducer } from "./moduleMasterReducer";
import { permissionReducer } from "./permissionReducer";
import { teamMemberReducer } from "./teamMemberReducer";
import { facilityReducer } from "./facilityReducer";
import { attributeReducer } from "./attributeReducer";
import { headerReducer } from "./headerReducer";
import { personalEventReducer } from "./personalEventReducer";
import { workplaceReducer } from "./workplaceReducer";
import { educationReducer } from "./educationReducer";
import { secondarySchoolReducer } from "./secondarySchoolReducer";
import { socialLinksReducer } from "./socialLinksReducer";
import { websiteReducer } from "./websiteReducer";
import { languageReducer } from "./languageReducer";
import { leagueReducer } from "./leagueReducer";
import { profileListReducer } from "./profileListReducer";
import { userInterestReducer } from "./userInterestReducer";
import { businessReducer } from "./businessReducer";
import { hobbiesReducer } from "./hobbiesReducer";
import { policyReducer } from "./policyReducer";
import { canvasFolderReducer } from "./canvasFolderReducer";
import { scheduleReducer } from "./scheduleReducer";
import { PaymentCredentialReducer } from "./paymentCredentialReducer";
import { teamDetailReducer } from "./teamDetailReducer";
import { embedVideoReducer } from "./embedVideoReducer";
import { FollowerReducer } from "./followReducer";
import { subscriptionReducer } from "./subscriptionReducer";
import { commentReduer } from "./commentReducer";
import { generalReducer } from "./generalReducer";
import { hashtagReducer } from "./hashtagReducer";
import { searchReducer } from "./searchReducer";
import { teamFollowReducer } from "./teamFollowReducer";
import { paymentSubscriptionReducer } from "./paymentSubscriptionReducer";
import { RecentUserPostReducer } from "./recentPostReducer";
import { newChatReducer } from "./newChatReducer";
import { notificationsReducer } from "./notificationsReducer";
import { UserLogoutReducer } from "./logoutReducer";
import { authReducer } from "./authReducer";
import { whiteBoardReducer } from "./whiteBoardReducer";
import { colorsReducer } from "./colorsReducer";
import { calendarExportReducer } from "./calendarExportReducer";
import { PlaybookTab } from "./playbookTabReducer";
import { teamMessageBoardReducer } from "./teamMessageBoardReducer";
import { teamCommentReduer } from "./teamCommentReducer";
import { myPlaybook } from "./myPlaybookReducer";
import { messageBoardSettingsReducer } from "./messageBoardSettingsReducer";

const appReducer = combineReducers({
  user: userReducer,
  banner: bannerReducer,
  company: companyReducer,
  dashboard: dashboardReducer,
  sports: sportsReducer,
  others: otherReducer,
  coach: coachReducer,
  team: teamReducer,
  teamNews: teamNewsReducer,
  teamPlayer: teamPlayerReducer,
  canvas: canvasReducer,
  post: postReducer,
  event: eventReducer,
  home: homeReducer,
  chat: chatReducer,
  teamSports: teamSportsReducer,
  moduleMaster: moduleMasterReducer,
  permission: permissionReducer,
  teamMember: teamMemberReducer,
  facility: facilityReducer,
  attribute: attributeReducer,
  header: headerReducer,
  personalEvent: personalEventReducer,
  workplace: workplaceReducer,
  education: educationReducer,
  secondarySchool: secondarySchoolReducer,
  socialLinks: socialLinksReducer,
  website: websiteReducer,
  language: languageReducer,
  league: leagueReducer,
  profileList: profileListReducer,
  userInterest: userInterestReducer,
  businessProfile: businessReducer,
  hobbies: hobbiesReducer,
  policy: policyReducer,
  canvasFolder: canvasFolderReducer,
  schedule: scheduleReducer,
  paymentCredential: PaymentCredentialReducer,
  teamDetails: teamDetailReducer,
  embedVieos: embedVideoReducer,
  followResult: FollowerReducer,
  subscription: subscriptionReducer,
  comment: commentReduer,
  general: generalReducer,
  hashtag: hashtagReducer,
  search: searchReducer,
  teamFollow: teamFollowReducer,
  paymentSubscription: paymentSubscriptionReducer,
  recentUserPost: RecentUserPostReducer,
  newChatReducer: newChatReducer,
  notifications: notificationsReducer,
  userLogout: UserLogoutReducer,
  auth: authReducer,
  whiteBoard: whiteBoardReducer,
  colors: colorsReducer,
  calendarExport: calendarExportReducer,
  playbookTab: PlaybookTab,
  teamPosts: teamMessageBoardReducer,
  teamComment: teamCommentReduer,
  playbook: myPlaybook,
  teamMessageBoard: messageBoardSettingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const middlewareList = [thunk];
const middleware = composeWithDevTools(applyMiddleware(...middlewareList));

export const store = createStore(rootReducer, middleware);
