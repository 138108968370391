import React from "react";

const FollowersSVG = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        id="fi-rr-following"
        width="24.002"
        height="24"
        viewBox="0 0 24.002 24"
      >
        <path
          id="Path_158"
          data-name="Path 158"
          d="M9,12A6,6,0,1,0,3,6a6,6,0,0,0,6,6ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z"
        />
        <path
          id="Path_159"
          data-name="Path 159"
          d="M9,14a9.011,9.011,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,1,1,14,0,1,1,0,0,0,2,0,9.011,9.011,0,0,0-9-9Z"
        />
        <path
          id="Path_160"
          data-name="Path 160"
          d="M22,7.875a2.107,2.107,0,0,0-2,2.2,2.107,2.107,0,0,0-2-2.2,2.107,2.107,0,0,0-2,2.2c0,1.73,2.256,3.757,3.38,4.659a.992.992,0,0,0,1.24,0c1.124-.9,3.38-2.929,3.38-4.659a2.107,2.107,0,0,0-2-2.2Z"
        />
      </svg>

    );

};
export default FollowersSVG;