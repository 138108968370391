import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  GET_TEAM_BY_ID_REQUEST,
  GET_TEAM_BY_ID_SUCCESS,
  GET_TEAM_BY_ID_FAILURE,
  STORE_NAME_RESET,
  FIND_TEAM_REQUEST,
  FIND_TEAM_SUCCESS,
  FIND_TEAM_FAILURE,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_LIST_FAILURE,
  GET_ALL_TEAMS_REQUEST,
  GET_ALL_TEAMS_SUCCESS,
  GET_ALL_TEAMS_FAILURE,
  EDIT_TEAM_ID,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  GET_ALL_TEAMS_BY_USER_ID_REQUEST,
  GET_ALL_TEAMS_BY_USER_ID_SUCCESS,
  GET_ALL_TEAMS_BY_USER_ID_FAILURE,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS,
  GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE,
  GET_ALL_TEAMS_WITH_PARENTID_REQUEST,
  GET_ALL_TEAMS_WITH_PARENTID_SUCCESS,
  GET_ALL_TEAMS_WITH_PARENTID_FAILURE,
  SUBTEAM_BY_PARENTID_REQUEST,
  SUBTEAM_BY_PARENTID_SUCCESS,
  SUBTEAM_BY_PARENTID_FAILURE,
  CURRENT_TEAM,
  GET_TEAM_CREDITS_COUNTS_REQUEST,
  GET_TEAM_CREDITS_COUNTS_SUCCESS,
  GET_TEAM_CREDITS_COUNTS_FAILURE,
  VERIFY_TEAM_SLUG_REQUEST,
  VERIFY_TEAM_SLUG_SUCCESS,
  VERIFY_TEAM_SLUG_FAILURE,
  CURRENT_TEAM_ROLE,
  LOCK_TEAM_DETAILS,
  TEAM_MEMBER_LIST_REQUEST,
  TEAM_MEMBER_LIST_SUCCESS,
  TEAM_MEMBER_LIST_FAILURE,
  UPDATE_TEAM_TAGLINE_REQUEST,
  UPDATE_TEAM_TAGLINE_SUCCESS,
  UPDATE_TEAM_TAGLINE_FAILURE,
  STORE_SELECTED_TEAM_LIST_REQUEST,
  STORE_SELECTED_TEAM_LIST_SUCCESS,
  STORE_SELECTED_TEAM_LIST_FAILURE,
  GET_ALL_ASSOCIATED_TEAMS_REQUEST,
  GET_ALL_ASSOCIATED_TEAMS_SUCCESS,
  GET_ALL_ASSOCIATED_TEAMS_FAILURE,
  GET_ALL_SELECTED_TEAM_REQUEST,
  GET_ALL_SELECTED_TEAM_SUCCESS,
  GET_ALL_SELECTED_TEAM_FAILURE,
  GET_TEAM_LIST_REQUEST,
  GET_TEAM_LIST_SUCCESS,
  GET_TEAM_LIST_FAILURE,
  UPDATE_TEAM_NAME_REQUEST,
  UPDATE_TEAM_NAME_SUCCESS,
  UPDATE_TEAM_NAME_FAILURE,
  CREATE_PARENT_TEAM_REQUEST,
  CREATE_PARENT_TEAM_SUCCESS,
  CREATE_PARENT_TEAM_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  DELETE_ALL_TEAM_REQUEST,
  DELETE_ALL_TEAM_SUCCESS,
  DELETE_ALL_TEAM_FAILURE,
} from "../constants/team";

import axios from "axios";

export const CreateTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/teamcreate`,
      values
    );
    dispatch({ type: CREATE_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_TEAM_FAILURE });
  }
};

export const CreateParentTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PARENT_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/createParentTeam`,
      values
    );
    dispatch({ type: CREATE_PARENT_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: CREATE_PARENT_TEAM_FAILURE });
  }
};

export const GetAllTeams = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAMS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/getAllTeam`
    );
    dispatch({ type: GET_ALL_TEAMS_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAMS_FAILURE });
  }
};

export const ResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const TeamResetState = () => async (dispatch) => {
  dispatch({ type: STORE_NAME_RESET });
};

export const GetTeamById = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_BY_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/teamById`,
      values
    );
    dispatch({ type: GET_TEAM_BY_ID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_TEAM_BY_ID_FAILURE });
  }
};

export const GetAllTeamData = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllTeamSportData`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
          }
    );
    dispatch({ type: TEAM_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_LIST_FAILURE });
  }
};

export const GetAllTeamMemberByTeamIdData = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_MEMBER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllTeamMemberList`,
      values
    );
    dispatch({ type: TEAM_MEMBER_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_MEMBER_LIST_FAILURE });
  }
};

export const GetAllTeamDataOld = (values) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllTeamSportData`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
          }
    );
    dispatch({ type: TEAM_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TEAM_LIST_FAILURE });
  }
};

export const SearchTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: FIND_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/searchSportAndTeamData`,
      values
    );
    dispatch({ type: FIND_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: FIND_TEAM_FAILURE });
  }
};

export const SetEditTeamId = (values) => async (dispatch) => {
  dispatch({ type: EDIT_TEAM_ID, payload: values });
};

export const UpdateTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/updateTeam`,
      values
    );
    dispatch({ type: UPDATE_TEAM_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_TEAM_FAILURE });
  }
};

export const UpdateTeamTagline = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TEAM_TAGLINE_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/updateTeam`,
      values
    );
    dispatch({ type: UPDATE_TEAM_TAGLINE_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: UPDATE_TEAM_TAGLINE_FAILURE });
  }
};

export const GetAllTeamDataByUserID = (values) => async (dispatch) => {
  // console.log("values@@@@@@@2222222", values);
  try {
    dispatch({ type: GET_ALL_TEAMS_BY_USER_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getTeamMemberDataWithTeam`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
          }
    );
    dispatch({ type: GET_ALL_TEAMS_BY_USER_ID_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAMS_BY_USER_ID_FAILURE });
  }
};

export const GetAllParentTeamDataByUserID = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PARENT_TEAMS_BY_USER_ID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllTeamsDataByiUserId`,
      values && Object.keys(values).length > 0
        ? values
        : {
            iUserId: sessionStorage.getItem("id"),
          }
    );
    dispatch({
      type: GET_ALL_PARENT_TEAMS_BY_USER_ID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_PARENT_TEAMS_BY_USER_ID_FAILURE });
  }
};

export const GetAllTeamByParentID = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAMS_WITH_PARENTID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllTeamsDataByiTeamId`,
      {
        iUserId: sessionStorage.getItem("id"),
      }
    );
    dispatch({
      type: GET_ALL_TEAMS_WITH_PARENTID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_TEAMS_WITH_PARENTID_FAILURE });
  }
};

export const GetAllSubTeamByParentID = (values) => async (dispatch) => {
  try {
    dispatch({ type: SUBTEAM_BY_PARENTID_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllChildTeamsData`,
      values
    );
    dispatch({
      type: SUBTEAM_BY_PARENTID_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: SUBTEAM_BY_PARENTID_FAILURE });
  }
};

export const SetCurrentTeam = (values) => async (dispatch) => {
  dispatch({ type: CURRENT_TEAM, payload: values });
};

export const GetAllTeamCreditsAndCount = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_CREDITS_COUNTS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/TeamSport/getAllInteractiveAndNonInteractiveSportDataAndCount`,
      {
        iUserId: sessionStorage.getItem("id"),
      }
    );
    dispatch({
      type: GET_TEAM_CREDITS_COUNTS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_TEAM_CREDITS_COUNTS_FAILURE });
  }
};

export const VerifyTeamSlug = (values) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_TEAM_SLUG_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/checkTeamSlugExists`,
      values
    );
    dispatch({
      type: VERIFY_TEAM_SLUG_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: VERIFY_TEAM_SLUG_FAILURE });
  }
};

export const SetCurrentTeamRole = (values) => async (dispatch) => {
  dispatch({
    type: CURRENT_TEAM_ROLE,
    payload: values,
  });
};

export const SetLockTeamDetails = (values) => async (dispatch) => {
  dispatch({
    type: LOCK_TEAM_DETAILS,
    payload: values,
  });
};

export const GetAllTeamsList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_TEAM_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllParentChildTeamsWithSport`,
      values
    );
    dispatch({
      type: GET_TEAM_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_TEAM_LIST_FAILURE });
  }
};
export const GetAllAssociatedTeamsList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_ASSOCIATED_TEAMS_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamMember/getAllUserAssociatesTeams`,
      values
    );
    dispatch({
      type: GET_ALL_ASSOCIATED_TEAMS_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_ASSOCIATED_TEAMS_FAILURE });
  }
};

export const StoreSelectedTeamList = (values) => async (dispatch) => {
  try {
    dispatch({ type: STORE_SELECTED_TEAM_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamHighlight/createTeamHighlight`,
      values
    );
    dispatch({
      type: STORE_SELECTED_TEAM_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: STORE_SELECTED_TEAM_LIST_FAILURE });
  }
};

export const GetSelectedTeamList = (values) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SELECTED_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/teamHighlight/getAllHighlightedData`,
      values
    );
    dispatch({
      type: GET_ALL_SELECTED_TEAM_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: GET_ALL_SELECTED_TEAM_FAILURE });
  }
};

export const UpdateTeamName = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TEAM_NAME_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/myTeamUpdate`,
      values
    );
    dispatch({
      type: UPDATE_TEAM_NAME_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: UPDATE_TEAM_NAME_FAILURE });
  }
};

export const DeleteTeam = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/deletingParentTeamsAnditsAllData`,
      values
    );
    dispatch({
      type: DELETE_TEAM_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: DELETE_TEAM_FAILURE });
  }
};

export const DeleteAllTeamsData = (values) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ALL_TEAM_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/Team/deleteAllTeamsData`,
      values
    );
    dispatch({
      type: DELETE_ALL_TEAM_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: DELETE_ALL_TEAM_FAILURE });
  }
};
