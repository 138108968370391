import React, { Suspense } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
  useNavigate,
  // useInRouterContext,
} from "react-router-dom";
import "./App.css";
import { store } from "./store/reducer";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { GetCompany } from "./store/action/companyAction";
import Loader from "./components/CustomComponents/Loader/Loader";
// import CreateFacilityTab from "./components/MasterCalendar/CreateFacilityTab";
import FacilityManagement from "./pages/FacilityManagement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BetaTesterAgreement from "./pages/BetaTesterAgreement";
import TermsOfUse from "./pages/TermsOfUse";
import {
  GetAllTeamData,
  GetTeamById,
  SetCurrentTeamRole,
} from "./store/action/teamAction";
import { GetAllPermissionByOtherRole } from "./store/action/permissionAction";
import { UserProfile, getTeamTypes } from "./store/action/userAction";
// import firebase from "./firebase";
import { getToken } from "firebase/messaging";
import firebase from "firebase/compat/app";
import "firebase/messaging";
import { getMessaging, onMessage } from "firebase/messaging";
import { SetFirebaseAuthToken } from "./store/action/authAction";
import LatestChat from "./components/NewChat/LatestChat";
// import { useBrowserType } from "./hooks/useBrowserType";
import { useMobileBrowserCheck } from "./hooks/useMobileBrowserCheck";
import AddWhiteBoard from "./pages/AddWhiteBoard";
import { ConfirmDialog } from "primereact/confirmdialog";

const MobileHome = React.lazy(() => import("./components/Home/MobileHome"));
const Features = React.lazy(() => import("./pages/Features"));
const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
// const Registration = React.lazy(() => import("./pages/Registration"));
const Registration = React.lazy(() => import("./pages/Registration"));
const Company = React.lazy(() => import("./pages/Company"));
const Price = React.lazy(() => import("./pages/Price"));
const Baseball = React.lazy(() => import("./pages/Baseball"));
const BaseballNew = React.lazy(() => import("./pages/BaseballNew"));
const Basketball = React.lazy(() => import("./pages/Basketball"));
const Football = React.lazy(() => import("./pages/Football"));
const Soccer = React.lazy(() => import("./pages/Soccer"));
const Hockey = React.lazy(() => import("./pages/Hockey"));
const Lacrosse = React.lazy(() => import("./pages/Lacrosse"));
const Cricket = React.lazy(() => import("./pages/Cricket"));
const Rugby = React.lazy(() => import("./pages/Rugby"));
const VolleyBall = React.lazy(() => import("./pages/VolleyBall"));
const FieldHockey = React.lazy(() => import("./pages/FieldHockey"));
const Softball = React.lazy(() => import("./pages/Softball"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
// const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const Thankyou = React.lazy(() => import("./pages/Thankyou"));
const Logout = React.lazy(() => import("./pages/Logout"));
// import PlayerOrParent from "./pages/PlayerOrParent"

const Upgrade = React.lazy(() => import("./pages/Upgrade"));
const TeamNews = React.lazy(() => import("./pages/TeamNews"));
const TeamNewsDetail = React.lazy(() => import("./pages/TeamNewsDetail"));
const DataNotFound = React.lazy(() => import("./pages/DataNotFound"));
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const ThankyouExistUser = React.lazy(() => import("./pages/ThankyouExistUser"));
// const CanvasHome = React.lazy(() => import("./pages/CanvasHome"))
const TeamPlaybook = React.lazy(() => import("./pages/TeamPlaybook"));
const MyPlaybook = React.lazy(() => import("./pages/MyPlaybook"));
const Profile = React.lazy(() => import("./pages/Profile"));
const MyTeams = React.lazy(() => import("./pages/MyTeams"));
const TeamChat = React.lazy(() => import("./pages/TeamChat"));
const TeamDetails = React.lazy(() => import("./pages/TeamDetails"));
const ViewAsPublic = React.lazy(() => import("./pages/ViewAsPublic"));
const TeamCalendar = React.lazy(() => import("./pages/TeamCalendar"));
const AddPlayer = React.lazy(() => import("./pages/AddPlayer"));
const AddCoachNew = React.lazy(() => import("./pages/AddCoachNew"));
const AddHeadCoachNew = React.lazy(() => import("./pages/AddHeadCoachNew"));
const SelectSport = React.lazy(() => import("./pages/SelectSport"));
const CreateTeam = React.lazy(() => import("./pages/CreateTeam"));
const CreateOrganisation = React.lazy(() =>
  import("./pages/CreateOrganisation")
);
const TeamFiles = React.lazy(() => import("./pages/TeamFiles"));
const TeamVideos = React.lazy(() => import("./pages/TeamVideos"));
const MasterVideos = React.lazy(() => import("./pages/MasterVideos"));
const EditProfile = React.lazy(() => import("./pages/EditProfile"));
const Products = React.lazy(() => import("./pages/Products"));
const ProductList = React.lazy(() => import("./pages/ProductList"));
const Cart = React.lazy(() => import("./pages/Cart"));

const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const MarketPlace = React.lazy(() => import("./pages/MarketPlace"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const BillingAddress = React.lazy(() => import("./pages/BillingAddress"));
const Role = React.lazy(() => import("./pages/Role"));
const AddMember = React.lazy(() => import("./pages/AddMember"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const BusinessProfile = React.lazy(() => import("./pages/BusinessProfile"));
const PaymentCretdentials = React.lazy(() =>
  import("./pages/PaymentCretdentials")
);
const PublicTeamProfile = React.lazy(() => import("./pages/PublicTeamProfile"));
const AddProduct = React.lazy(() => import("./pages/AddProduct"));
const BusinessProductStats = React.lazy(() =>
  import("./pages/BusinessProductStats")
);
const AddCanvasTestMerge = React.lazy(() =>
  import("./pages/AddCanvasTestMerge")
);
const UserInterest = React.lazy(() => import("./pages/UserInterest"));
const UserInterestTwo = React.lazy(() => import("./pages/UserinterestTwo"));
const PersonalCalendar = React.lazy(() => import("./pages/PersonalCalendar"));
const MasterCalendar = React.lazy(() => import("./pages/MasterCalendar"));
const ViewAsPublicTeam = React.lazy(() => import("./pages/ViewAsPublicTeam"));
const OtpVerification = React.lazy(() => import("./pages/OtpVerification"));
const PrivacyPolicyMobile = React.lazy(() =>
  import("./pages/PrivacyPolicyMobile")
);
const TermsOfUseMobile = React.lazy(() => import("./pages/TermsOfUseMobile"));
const BetaTesterAgreementMobile = React.lazy(() =>
  import("./pages/BetaTesterAgreementMobile")
);
const SettingNew = React.lazy(() => import("./pages/SettingNew"));
const TeamSetting = React.lazy(() => import("./pages/TeamSetting"));
const TeamAreaSetting = React.lazy(() => import("./pages/TeamAreaSetting"));
const PublicProfile = React.lazy(() => import("./pages/PublicProfile"));
const PublicProfileAuth = React.lazy(() => import("./pages/PublicProfileAuth"));
const Followers = React.lazy(() => import("./pages/Followers"));
const FollowersNew = React.lazy(() => import("./pages/Followers-new"));
// const Subscription = React.lazy(() => import("./pages/Subscription"));
const SearchFilter = React.lazy(() => import("./pages/SearchFilter"));
// const ChatNew = React.lazy(() => import("./pages/ChatNew"));
const SubscriptionPaymenent = React.lazy(() =>
  import("./pages/SubscriptionPaymenent")
);

const MessageBoardSettings = React.lazy(() =>
  import("./pages/MessageBoardSettings")
);
const MessageBoardTeamPost = React.lazy(() =>
  import("./pages/MessageBoardTeamPost")
);
const MessageBoardTeams = React.lazy(() => import("./pages/MessageBoardTeams"));

function AppWrapper() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const browserType = useBrowserType();
  const isMobile = useMobileBrowserCheck();
  const { companyData } = useSelector((state) => state.company);
  const { teamData, currentTeamRoleId, teamListData } = useSelector(
    (state) => state.team
  );
  const { firebaseAuthToken } = useSelector((state) => state.auth);
  const location = useLocation();
  const { userProfileData, teamTypes } = useSelector((state) => state.user);
  // const [isPlayer, setIsPlayer] = React.useState(false);

  // React.useEffect(() => {
  //   if (userProfileData && userProfileData.code === "200") {
  //     if (userProfileData.data && userProfileData.data.iRoleId === 4) {
  //       setIsPlayer(true);
  //     }
  //   }
  // }, [userProfileData]);

  // getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
  // const getOrRegisterServiceWorker = async () => {
  //   if (
  //     "serviceWorker" in navigator &&
  //     typeof window.navigator.serviceWorker !== "undefined"
  //   ) {
  //     const serviceWorker = await window.navigator.serviceWorker
  //       .getRegistration("/firebase-push-notification-scope");
  //     if (serviceWorker) return serviceWorker;
  //     return window.navigator.serviceWorker.register(
  //       "/firebase-messaging-sw.js",
  //       {
  //         scope: "/firebase-push-notification-scope",
  //       }
  //     );
  //   }
  //   throw new Error("The browser doesn`t support service worker.");
  // };

  React.useEffect(() => {
    if (!teamTypes) {
      dispatch(getTeamTypes());
    }
  }, [teamTypes, dispatch]);

  React.useEffect(() => {
    try {
      if (!firebaseAuthToken) {
        const firebaseConfig = {
          apiKey: "AIzaSyASqRRO-horF5USuMf0PO1_vP0nn3W0xf4",
          authDomain: "yo-playbook.firebaseapp.com",
          projectId: "yo-playbook",
          storageBucket: "yo-playbook.appspot.com",
          messagingSenderId: "1004134748660",
          appId: "1:1004134748660:web:d9a9eac16b1c738b5a29a1",
          measurementId: "G-QXN0EPK7KY",
        };
        const app = firebase.initializeApp(firebaseConfig);
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const messaging = getMessaging(app);
            getToken(messaging, {
              vapidKey:
                "BAqD4yAvOTpWqSx76_WqkcR0Y1Zevh9et4EllwxbNm8RDc3HzdO58gW5cFl3zbK_Px-FPExYk98_W-ctSylUYsE",
            }).then((currentToken) => {
              if (currentToken) {
                dispatch(SetFirebaseAuthToken(currentToken));
                // console.log("firebaseAuthToken: ", currentToken);
              } else {
                console.log("Can not get token");
              }
            });
            // }
          } else {
            console.log("Do not have permission!");
          }
        });
      }
    } catch (error) {
      console.log("error in firebase", error);
    }
  }, [dispatch, firebaseAuthToken]);

  React.useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      if (Notification.permission === "granted") {
        // You can show notifications directly since permission is already granted.
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: "icon.png", // Path to an icon image for the notification.
        });
      } else if (Notification.permission !== "denied") {
        // Request permission from the user.
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(payload.notification.title, {
              title: payload.notification.title,
              body: payload.notification.body,
              icon: "icon.png", // Path to an icon image for the notification.
            });
          }
        });
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      !userProfileData &&
      sessionStorage.getItem("id") &&
      sessionStorage.getItem("id").length > 0
    ) {
      dispatch(UserProfile({ iUserId: sessionStorage.getItem("id") }));
    }
  }, [userProfileData, dispatch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [location]);

  React.useEffect(() => {
    if (location.pathname.length > 0) {
      // document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'http://myweb.com/image.jpg')

      const element = document.querySelectorAll("meta[property=og\\:image]")[0];
      if (
        element &&
        userProfileData &&
        userProfileData.code === "200" &&
        userProfileData.data &&
        userProfileData.data.vImage &&
        userProfileData.data.vImage.replace(/^.*[\\/]/, "") !== null
      ) {
        element.setAttribute("content", userProfileData.data.vImage);
      } else {
        element.setAttribute(
          "content",
          "https://www.yoplaybook.com/assets/img/playbook-Logo.png"
        );
      }
      let titleName =
        location.pathname.replace("/", "").trim().includes("personal/") === true
          ? "personal"
          : location.pathname.replace("/", "").trim().charAt(0).toUpperCase() +
            location.pathname.replace("/", "").trim().slice(1);
      switch (location.pathname) {
        case "/register":
        case "/login":
        case "/forgot-password":
        case "/select-sport":
        case "/my-teams":
        case "/calendar":
        case "/market-place":
        case "/create-team":
          return (document.getElementById(
            "title"
          ).innerHTML = `${titleName} | Yo Playbook`);
        case "/profile":
        case `/personal/${userProfileData?.data?.vPersonalSlug}`:
        case "/edit-profile":
          return (document.getElementById("title").innerHTML = `${
            userProfileData &&
            userProfileData?.code === "200" &&
            userProfileData?.data &&
            userProfileData?.data?.vFirstName &&
            userProfileData?.data?.vLastName &&
            userProfileData?.data?.vFirstName.length > 0 &&
            userProfileData?.data?.vLastName.length > 0
              ? userProfileData?.data?.vFirstName.charAt(0).toUpperCase() +
                userProfileData?.data?.vFirstName.slice(1) +
                " " +
                (userProfileData?.data?.vLastName.charAt(0).toUpperCase() +
                  userProfileData?.data?.vLastName.slice(1)) +
                " | Yo Playbook"
              : "Yo Playbook"
          } `);
        case "/business-profile":
          return (document.getElementById("title").innerHTML = `${
            userProfileData?.code === "200" &&
            userProfileData?.data?.vBusinessName !== null
              ? userProfileData?.data?.vBusinessName + " | Yo Playbook"
              : "Business | Yo Playbook"
          } `);
        default:
          return (document.getElementById("title").innerHTML = "Yo Playbook");
      }
    }
  }, [location, userProfileData]);

  // const isViewTeamCalendarPermit = CheckPermission(4);
  React.useEffect(() => {
    if (!companyData) {
      dispatch(
        GetCompany({
          eConfigType: "Company",
        })
      );
    }
  }, [dispatch, companyData]);

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  for (let i = 0; i < companyData?.data?.length; i++) {
    if (companyData?.data[i]?.vName === "COMPANY_FAVICON") {
      link.href =
        `${process.env.REACT_APP_GET_ASSET_PATH}logo/` +
        companyData?.data[i]?.vValue;
    }
  }

  React.useEffect(() => {
    if (
      !teamData &&
      sessionStorage.getItem("id") &&
      sessionStorage.getItem("id").length > 0 &&
      sessionStorage.getItem("teamId") &&
      sessionStorage.getItem("teamId").length > 0
    ) {
      const values = {
        iUserId: sessionStorage.getItem("id"),
        iTeamId: sessionStorage.getItem("teamId"),
      };
      if (parseInt(values.iUserId) > 0 && parseInt(values.iTeamId) > 0) {
        dispatch(GetTeamById(values));
      }
    }
  }, [dispatch, teamData, location]);

  React.useEffect(() => {
    if (
      !teamListData &&
      sessionStorage.getItem("id") &&
      sessionStorage.getItem("id").length > 0 &&
      sessionStorage.getItem("teamId") &&
      sessionStorage.getItem("teamId").length > 0
    ) {
      dispatch(GetAllTeamData());
    }
  }, [dispatch, teamListData]);

  React.useEffect(() => {
    if (
      !currentTeamRoleId &&
      teamListData &&
      teamListData.code === "200" &&
      teamListData.data &&
      teamListData.data.length > 0 &&
      sessionStorage.getItem("id") &&
      sessionStorage.getItem("id").length > 0 &&
      sessionStorage.getItem("teamId") &&
      sessionStorage.getItem("teamId").length > 0
    ) {
      for (let i = 0; i < teamListData.data.length; i++) {
        if (
          teamListData.data[i].team_members &&
          teamListData.data[i].team_members.length > 0 &&
          teamListData.data[i].team_members.find(
            (item) =>
              item.iTeamId.toString() === sessionStorage.getItem("teamId")
          )
        ) {
          const team = teamListData.data[i].team_members.find(
            (item) =>
              item.iTeamId.toString() === sessionStorage.getItem("teamId")
          );
          if (team && Object.keys(team).length > 0) {
            dispatch(SetCurrentTeamRole(team.iRoleId));
          }
        }
      }
    }
  }, [teamListData, currentTeamRoleId, dispatch]);

  React.useEffect(() => {
    setTimeout(() => {
      sessionStorage.clear();
      navigate("/");
    }, 10800 * 1000);
  }, [navigate]);

  React.useEffect(() => {
    if (
      teamData &&
      teamData.code === "200" &&
      teamData.data &&
      currentTeamRoleId
    ) {
      const values = {
        iRoleId: currentTeamRoleId,
        iUserId: sessionStorage.getItem("id"),
        iTeamId: sessionStorage.getItem("teamId"),
      };
      dispatch(GetAllPermissionByOtherRole(values));
    }
  }, [dispatch, teamData, currentTeamRoleId]);

  React.useEffect(() => {
    if (
      isMobile &&
      location &&
      location.pathname.length > 0 &&
      location.pathname !== "/" &&
      location.pathname !== "/change-password" &&
      location.pathname !== "/privacy-policy-mobile" &&
      location.pathname !== "/beta-tester-agreement-mobile" &&
      location.pathname !== "/terms-use-mobile" &&
      location.pathname !== "/app-links"
    ) {
      navigate("/app-links");
    } else if (isMobile) {
      window.history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function (event) {
        window.location.replace(`/`);
      });
    }
  }, [isMobile, location, navigate]);

  return (
    <>
      {/* <Router> */}
      <ConfirmDialog />
      <Suspense fallback={<Loader />}>
        <Routes>
          {isMobile ? (
            <>
              <Route exact path="/" element={<Home />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route
                path="/privacy-policy-mobile"
                element={<PrivacyPolicyMobile />}
              />
              <Route
                path="/beta-tester-agreement-mobile"
                element={<BetaTesterAgreementMobile />}
              />
              <Route path="/terms-use-mobile" element={<TermsOfUseMobile />} />
              <Route path={"/app-links"} element={<MobileHome />} />
            </>
          ) : (
            <>
              <Route exact path="/" element={<Home />} />
              <Route path="/features" element={<Features />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/company" element={<Company />} />
              <Route path="/price" element={<Price />} />
              <Route path="/sports/baseball" element={<Baseball />} />
              <Route path="/baseball-new" element={<BaseballNew />} />
              <Route path="/sports/basketball" element={<Basketball />} />
              <Route path="/sports/football" element={<Football />} />
              <Route path="/sports/soccer" element={<Soccer />} />
              <Route path="/sports/hockey" element={<Hockey />} />
              <Route path="/sports/lacrosse" element={<Lacrosse />} />
              <Route path="/sports/cricket" element={<Cricket />} />
              <Route path="/sports/rugby" element={<Rugby />} />
              <Route path="/sports/volley-ball" element={<VolleyBall />} />
              <Route path="/sports/field-hockey" element={<FieldHockey />} />
              <Route path="/sports/softball" element={<Softball />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/thankyou" element={<Thankyou />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/upgrade" element={<Upgrade />} />
              <Route path="/team-news" element={<TeamNews />} />
              <Route path="/team-news-detail" element={<TeamNewsDetail />} />
              <Route path="/data-not-found" element={<DataNotFound />} />
              <Route path="/page-not-found" element={<PageNotFound />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog-detail" element={<BlogDetail />} />
              <Route path="/thankyou-user" element={<ThankyouExistUser />} />
              <Route path="/team-playbook" element={<TeamPlaybook />} />
              <Route path="/my-playbook" element={<MyPlaybook />} />
              <Route path="/whiteboard" element={<AddWhiteBoard />} />
              <Route path="/team-calendar" element={<TeamCalendar />} />
              <Route path="/master-calendar" element={<MasterCalendar />} />
              <Route path="/calendar" element={<PersonalCalendar />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/team-chat" element={<TeamChat />} />
              <Route path="/my-teams" element={<MyTeams />} />
              <Route path="/teams-details" element={<TeamDetails />} />
              <Route
                path="/payment/subscriprion"
                element={<SubscriptionPaymenent />}
              />
              <Route
                path="/team/auth/:vTeamSlug"
                element={<ViewAsPublicTeam />}
              />
              <Route path="/public-view" element={<ViewAsPublic />} />
              {/* <Route path="/team/:vTeamSlug" element={<PublicTeamProfile />} /> */}
              {teamTypes && teamTypes.length > 0 ? (
                teamTypes.map((item, key) => (
                  <Route
                    key={key}
                    path={`/${item.vTitle
                      .toLowerCase()
                      .replace(" ", "-")}/:vTeamSlug`}
                    element={<PublicTeamProfile />}
                  />
                ))
              ) : (
                <></>
              )}
              <Route
                path="/teams-details/:vTeamSlug"
                element={<ViewAsPublicTeam />}
              />
              <Route
                path="/personal/:vPersonalSlug"
                element={<ViewAsPublic />}
              />
              <Route path="/select-sport" element={<SelectSport />} />
              <Route path="/create-team" element={<CreateTeam />} />
              <Route
                path="/create-organization"
                element={<CreateOrganisation />}
              />
              <Route
                path="/edit-organization"
                element={<CreateOrganisation />}
              />
              {/* <Route path="/add-player" element={<AddPlayer />} /> */}
              <Route path="/add-team-member" element={<AddPlayer />} />
              <Route path="/add-coach" element={<AddCoachNew />} />
              <Route path="/add-head-coach" element={<AddHeadCoachNew />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/edit-business-profile" element={<EditProfile />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products-list" element={<ProductList />} />

              <Route path="/products-details" element={<ProductDetails />} />
              <Route path="/market-place" element={<MarketPlace />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/billing-address" element={<BillingAddress />} />
              {userProfileData &&
              userProfileData.code === "200" &&
              userProfileData.data &&
              userProfileData.data.iRoleId === 4 ? (
                <></>
              ) : (
                <Route path="/roles" element={<Role />} />
              )}
              <Route path="/add-member" element={<AddMember />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/business-profile" element={<BusinessProfile />} />
              <Route path="/business-add-product" element={<AddProduct />} />
              <Route
                path="/business-products-stats"
                element={<BusinessProductStats />}
              />
              {/* create Facilit basis */}
              {userProfileData &&
              userProfileData.code === "200" &&
              userProfileData.data &&
              userProfileData.data.iRoleId === 4 ? (
                <></>
              ) : (
                <Route
                  path="/create-facility"
                  element={<FacilityManagement />}
                />
              )}
              <Route path="/create-play" element={<AddCanvasTestMerge />} />
              <Route path="/user-interest" element={<UserInterest />} />
              <Route path="/user-interest-two" element={<UserInterestTwo />} />
              <Route path="/otp-verification" element={<OtpVerification />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/beta-tester-agreement"
                element={<BetaTesterAgreement />}
              />
              <Route path="/terms-use" element={<TermsOfUse />} />
              <Route path="/team-files" element={<TeamFiles />} />
              <Route
                path="/privacy-policy-mobile"
                element={<PrivacyPolicyMobile />}
              />
              <Route
                path="/beta-tester-agreement-mobile"
                element={<BetaTesterAgreementMobile />}
              />
              <Route path="/terms-use-mobile" element={<TermsOfUseMobile />} />
              <Route path="/followers" element={<Followers />} />
              <Route path="/followers-new" element={<FollowersNew />} />
              {/* <Route path="/subscription" element={<Subscription />} /> */}
              <Route path="/settings" element={<SettingNew />} />
              {userProfileData &&
              userProfileData.code === "200" &&
              userProfileData.data &&
              userProfileData.data.iRoleId === 4 ? (
                <></>
              ) : (
                <Route path="/master-videos" element={<MasterVideos />} />
              )}

              <Route path="/team-videos" element={<TeamVideos />} />

              <Route path="/edit-team-details" element={<TeamSetting />} />
              <Route path="/teams-settings" element={<TeamAreaSetting />} />
              {userProfileData &&
              userProfileData.code === "200" &&
              userProfileData.data &&
              userProfileData.data.iRoleId === 4 ? (
                <></>
              ) : (
                <Route
                  path="/payment-methods"
                  element={<PaymentCretdentials />}
                />
              )}
              <Route path="/:vPersonalSlug" element={<PublicProfile />} />
              <Route
                path="/auth/:vPersonalSlug"
                element={<PublicProfileAuth />}
              />
              <Route path="/search-filter" element={<SearchFilter />} />
              <Route
                path="/message-board-settings"
                element={<MessageBoardSettings />}
              />
              <Route
                path="/team-message-board-settings"
                element={<MessageBoardSettings />}
              />
              <Route
                path="/message-board-posts"
                element={<MessageBoardTeamPost />}
              />
              <Route
                path="/team-message-board-posts"
                element={<MessageBoardTeamPost />}
              />
              <Route
                path="/message-board-teams"
                element={<MessageBoardTeams />}
              />

              <Route path="/*" element={<PageNotFound />} />
              {/* <Route path="/chat" element={<ChatNew />} /> */}
              <Route path="/chat" element={<LatestChat />} />
            </>
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppWrapper;
