export const GET_ALL_NOTIFICATIONS_REQUEST = "GET_ALL_NOTIFICATIONS_REQUEST";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_FAILURE = "GET_ALL_NOTIFICATIONS_FAILURE";

export const GET_NOTIFICATION_COUNT_REQUEST = "GET_NOTIFICATION_COUNT_REQUEST";
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_FAILURE = "GET_NOTIFICATION_COUNT_FAILURE";

export const SET_NOTIFICATION_SEEN_REQUEST = "SET_NOTIFICATION_SEEN_REQUEST";
export const SET_NOTIFICATION_SEEN_SUCCESS = "SET_NOTIFICATION_SEEN_SUCCESS";
export const SET_NOTIFICATION_SEEN_FAILURE = "SET_NOTIFICATION_SEEN_FAILURE";

export const RESET = "RESET";

export const NOTIFICATION_INVITATION_REQUEST =
  "NOTIFICATION_INVITATION_REQUEST";
export const NOTIFICATION_INVITATION_SUCCESS =
  "NOTIFICATION_INVITATION_SUCCESS";
export const NOTIFICATION_INVITATION_FAILURE =
  "NOTIFICATION_INVITATION_FAILURE";

export const GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST =
  "GET_MESSAGE_BOARD_NOTIFICATION_COUNT_REQUEST";
export const GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS =
  "GET_MESSAGE_BOARD_NOTIFICATION_COUNT_SUCCESS";
export const GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE =
  "GET_MESSAGE_BOARD_NOTIFICATION_COUNT_FAILURE";

export const GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST =
  "GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_REQUEST";
export const GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS =
  "GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_SUCCESS";
export const GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE =
  "GET_TEAM_MSG_BOARD_NOTIFICATION_COUNT_FAILURE";
